'use strict';
/*eslint-disable no-unused-expressions*/
window.App = {
	'version': '0.1',
	'config': {
		'breakpoints': {
			'small': 0,
			'medium': 430,
			'large': 600,
			'xlarge': 768,
			'xxlarge': 906
		}
	},
	'jsdata': {},
	'modules': {},
	'views': {}
};
